/* You can add global styles to this file, and also import other style files */
html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }

.custom-textbox {
    @apply appearance-none border border-gray-400 rounded w-full py-3 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500
}

.dialog-blur {
    background: rgba(0,0,0,0.5);
    backdrop-filter: blur(4px);
  }

  .select2-container .select2-selection--single {
    height: 32.5px !important;
  }

  .select2-container--disabled .select2-selection__arrow {
    display: none;
  }

  .select2-disabled {
    background-color: #eee;
  }

  .checkbox-detail mat-checkbox {
    display: flex !important;
  }

  .checkbox-detail mat-checkbox label {
    display: block;
  }